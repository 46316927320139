import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  arrow: {
    position: 'absolute',
    top: '50%',
    zIndex: 3,
    padding: 0,
    '& i': {
      fontSize: 32,
      color: theme.palette.primary.dark,
    },
  },
  arrowLeft: {
    transform: 'translate(-15px, calc(50% - 16px))',
    left: '-40px',

    '& i': {
      display: 'flex',
      transform: 'rotate(90deg)',
    },
  },
  arrowRight: {
    transform: 'translate(15px, calc(50% - 16px))',
    right: '-40px',
    '& i': {
      display: 'flex',
      transform: 'rotate(-90deg)',
    },
  },
  [theme.breakpoints.down(1400)]: {
    arrowLeft: {
      left: 0,
    },
    arrowRight: {
      right: 0,
    },
  },
}));

export default useStyles;
