import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  feefoCircleOne: {
    position: 'absolute',
    width: 232,
    height: 232,
    top: '-100px',
    left: '-188px',
  },
  feefoCircleOTwo: {
    position: 'absolute',
    width: 100,
    height: 100,
    bottom: '-30px',
    right: '-65px',
  },
  textCenter: {
    textAlign: 'center',
  },
  readAllReviews: {
    cursor: 'pointer',

    '& feefowidget-container-lightbox-service': {
      display: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',

    '& a': {
      transition: 'opacity 0.2s',

      '&:hover': {
        opacity: '0.8',
      },
    },
  },
  textWrapper: {
    padding: '16px 0 24px',
  },
  feffoTitle: {
    display: 'none',
    margin: '0 auto 16px',
    width: '85%',
  },
  subtitle: { color: theme.palette.info.main, marginBottom: 40, marginTop: 24 },
  subtitle1: { marginBottom: 16 },
  logo: { height: 36, width: 153, marginBottom: 24 },
  averageRating: { marginBottom: 8 },
  [theme.breakpoints.up('sm')]: {
    feefoCircleOTwo: {
      width: 150,
      height: 150,
      right: '-95px',
      bottom: '-50px',
    },
    textWrapper: {
      padding: '80px 0 40px',
    },
    feffoTitle: {
      display: 'block',
      marginBottom: 8,
    },
  },
  [theme.breakpoints.up('md')]: {
    feefoCircleOne: {
      display: 'block',
      width: 466,
      height: 466,
      left: '-405px',
      top: 0,
      transform: 'translateY(-50%)',
    },
    feefoCircleOTwo: {
      width: 352,
      height: 352,
      bottom: '-180px',
      right: '-240px',
    },
  },
  [theme.breakpoints.up('lg')]: {
    feefoCircleOne: {
      top: 30,
      width: 450,
      height: 450,
      left: '-495px',
    },
    feefoCircleOTwo: {
      display: 'block',
      bottom: '-148px',
      right: '-205px',
      transform: 'translateX(50%)',
    },
    textWrapper: {
      padding: '144px 0 40px',
    },
  },
}));

export default useStyles;
