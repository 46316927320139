import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  testimonial: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 40,
    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    padding: '44px 40px 40px',
    height: 320,
    margin: '0 15px',
  },
  ratingWrapper: { marginBottom: 28 },
  title: {
    marginBottom: 11,
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    textOverflow: 'ellipsis',
    height: '1.5rem',
  },
  description: {
    marginBottom: 24,
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 3,
    textOverflow: 'ellipsis',
  },
  owner: { color: theme.palette.info.main, margin: '0 -20px' },
}));

export default useStyles;
