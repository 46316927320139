import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  testimonialWrapper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
  },
  loaderWrapper: {
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
