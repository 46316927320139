/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import Slider, { CustomArrowProps, ResponsiveObject } from 'react-slick';

import useStyles from '@components/common/react-slick/styles';

interface Props {
  arrows: boolean;
  autoplay?: boolean;
  autoplaySpeed?: number;
  breakpoint?: ResponsiveObject[];
  centerMode?: boolean;
  cssEase?: string;
  dots: boolean;
  infinite: boolean;
  initialSlide?: number;
  pauseOnHover?: boolean;
  slides: JSX.Element[];
  slidesToScroll: number;
  slidesToShow: number;
  speed?: number;
  swipe: boolean;
  swipeToSlide: boolean;
  variableWidth?: boolean;
}

const ReactSlick: React.FC<Props> = ({
  slides,
  speed = 500,
  slidesToShow,
  swipe,
  swipeToSlide,
  infinite,
  dots,
  variableWidth,
  slidesToScroll,
  breakpoint,
  arrows,
  autoplay,
  autoplaySpeed = 3000,
  pauseOnHover,
  cssEase = 'ease',
  centerMode = false,
  initialSlide = 0,
}) => {
  const classes = useStyles();

  const SampleNextArrow = (props: CustomArrowProps) => {
    const { style, onClick } = props;

    return (
      <button
        onClick={onClick}
        type="button"
        className={classNames(classes.arrow, classes.arrowRight)}
        style={{ ...style }}
      >
        <i className="icon-arrow" />
      </button>
    );
  };

  const SamplePrevArrow = (props: CustomArrowProps) => {
    const { style, onClick } = props;

    return (
      <button
        onClick={onClick}
        className={classNames(classes.arrow, classes.arrowLeft)}
        type="button"
        style={{ ...style }}
      >
        <i className="icon-arrow" />
      </button>
    );
  };

  const settings = {
    centerMode,
    initialSlide,
    cssEase,
    dots,
    dotsClass: 'slick-dots',
    infinite,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: breakpoint,
    slidesToScroll,
    slidesToShow,
    swipeToSlide,
    variableWidth,
    swipe,
    arrows,
    autoplay,
    autoplaySpeed,
    pauseOnHover,
    speed,
  };

  return <Slider {...settings}>{slides}</Slider>;
};

export default ReactSlick;
