import { Typography } from '@material-ui/core';

import useStyles from '@components/common/stars-generator/styles';
import { Rating } from '@store/landings';

interface Props {
  rating: Rating;
}

const StarsGenerator: React.FC<Props> = (props) => {
  const { rating } = props;

  const classes = useStyles();

  const ratingPercentage = rating.rating * 20;

  return (
    <div className={classes.wrapper}>
      <span className={classes.one}>
        <span
          className={classes.two}
          style={{ width: `${ratingPercentage}%` }}
        />
      </span>
      <Typography variant="subtitle1">{rating.rating}</Typography>
    </div>
  );
};

export default StarsGenerator;
