import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';

import Button from '@components/common/button';
// eslint-disable-next-line import/order
import useStyles from '@components/common/banner-with-cards/styles';
import idNormalize from '@utils/id-normalize';

interface CardAction {
  href: string;
  text: string;
  disabled?: true;
}

export interface CardInfo {
  icon: JSX.Element;
  iconWithShadow?: true;
  title?: string;
  description: string | JSX.Element; // pass key to JSX.Element
  action?: CardAction;
  expandable?: true;
}

interface Props {
  info: CardInfo[];
  separated?: true;
  clickableOnMobile?: true;
}

const BannerWithCards: React.FC<Props> = (props) => {
  const { info, separated, clickableOnMobile } = props;

  const classes = useStyles();

  const cards = info.map((card) => {
    const cardItem = (
      <div
        className={classNames(classes.card, classes.gridAreas, {
          [classes.expandable]: card.expandable,
        })}
        key={
          card.title ||
          (typeof card.description === 'string'
            ? card.description
            : card.description.key)
        }
      >
        <div
          className={classNames(classes.cardIcon, {
            [classes.withShadow]: card.iconWithShadow,
          })}
        >
          {card.icon}
        </div>

        {card.title && (
          <Typography variant="subtitle1">{card.title}</Typography>
        )}
        <Typography variant="body2">{card.description}</Typography>

        {card.action && (
          <>
            <div className={classes.expander}>
              <Button
                id={`${idNormalize(card.action.text)}-button`}
                arrow={card.expandable ? undefined : 'rightSide'}
                styleType="containedBlue"
                className={classes.button}
                text={card.action.text}
                href={card.action.href}
                disabled={card.action.disabled}
              />
            </div>
            {card.expandable && (
              <div className={classes.blueText}>
                {card.action.text}
                <i className="icon-arrow-right" />
              </div>
            )}
          </>
        )}
      </div>
    );

    if (card.action && clickableOnMobile) {
      return (
        <div
          className={classes.gridAreas}
          key={
            card.title ||
            (typeof card.description === 'string'
              ? card.description
              : card.description.key)
          }
        >
          <div className={classes.hideOnMobile}>{cardItem}</div>

          <Link href={card.action.href} scroll={!card.action.disabled} passHref>
            <a
              id={`${idNormalize(card.action.text)}-link`}
              className={classes.displayOnMobile}
            >
              <div
                className={classNames(classes.card, classes.gridAreas, {
                  [classes.expandable]: card.expandable,
                })}
                key={
                  card.title ||
                  (typeof card.description === 'string'
                    ? card.description
                    : card.description.key)
                }
              >
                <div
                  className={classNames(classes.cardIcon, {
                    [classes.withShadow]: card.iconWithShadow,
                  })}
                >
                  {card.icon}
                </div>

                {card.title && (
                  <Typography variant="subtitle1">{card.title}</Typography>
                )}
                <Typography variant="body2">{card.description}</Typography>

                {card.action && card.expandable && (
                  <div className={classes.blueText}>
                    {card.action.text}
                    <i className="icon-arrow-right" />
                  </div>
                )}
              </div>
            </a>
          </Link>
        </div>
      );
    }

    return cardItem;
  });

  return (
    <section
      className={classNames(classes.cardsWrapper, {
        [classes.separated]: separated,
      })}
    >
      {cards}
    </section>
  );
};

export default BannerWithCards;
