import { Container, Link as MaterialLink, Typography } from '@material-ui/core';
import classnames from 'classnames';

import useStyles from '@components/common/feefo-rating/styles';
import Feefo from '@public/svg/feefo.svg';
import { Rating } from '@store/landings';

import Figures, { Circle } from '../figures';
import StarsGenerator from '../stars-generator';

interface Props {
  overalRating?: Rating;
}

const FeefoRating: React.FC<Props> = (props) => {
  const { overalRating } = props;

  const classes = useStyles();

  const feefoFigureData: Circle[] = [
    {
      background: 'blueGradient',
      classWrapper: classes.feefoCircleOne,
    },
    {
      background: 'pink',
      classWrapper: classes.feefoCircleOTwo,
    },
  ];

  const handleModalOpen = () => {
    FeefoToggle('popup');
  };

  return (
    <section className={classes.wrapper}>
      <Container maxWidth={false}>
        <div className={classes.wrapper}>
          <Figures circles={feefoFigureData} />
          <div className={classes.textWrapper}>
            <Typography
              variant="h3"
              className={classnames(classes.feffoTitle, classes.textCenter)}
            >
              The UK’s fastest growing will writing service, guiding you through
              wills and probate from the comfort of your own home
            </Typography>
            <Typography variant="body2" className={classes.subtitle}>
              What our customers are saying
            </Typography>
            <Feefo className={classes.logo} />
            <Typography variant="subtitle2" className={classes.averageRating}>
              Average Customer Rating:
            </Typography>
            {overalRating && <StarsGenerator rating={overalRating} />}
            <Typography variant="body2" className={classes.subtitle}>
              Independent Service Rating based on 1773 verified
              <br /> reviews over the past year.
            </Typography>

            <MaterialLink
              type="button"
              component="button"
              onClick={handleModalOpen}
            >
              <Typography
                variant="button"
                className={classnames('underline', classes.readAllReviews)}
              >
                Read all reviews
              </Typography>
            </MaterialLink>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FeefoRating;
