import { makeStyles } from '@material-ui/core/styles';

import transparentize from '@utils/transparentize';

const useStyles = makeStyles((theme) => ({
  cardsWrapper: {
    display: 'grid',
    gap: 30,
    gridTemplate: '"a" auto "b" auto "c" auto / 1fr',

    '&$separated': {
      gap: 0,

      '& $card': {
        padding: '40px 0',
        backgroundColor: 'transparent',
        boxShadow: 'none',

        '&:nth-child(n+2)::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: '50%',
          width: '100%',
          height: 2,
          transform: 'translate(-50%, -50%)',
          backgroundColor: transparentize(theme.palette.info.main, 0.3),
        },
      },
    },
  },
  separated: {
    /* nesting */
  },
  expandable: {
    /* nesting */
  },
  card: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.1)',
    borderRadius: 16,
    textAlign: 'center',
    padding: '40px 16px',
    width: '100%',
    height: '100%',

    '& h6': {
      marginBottom: 8,
    },

    '&$expandable': {
      padding: 16,
      paddingLeft: 72,
      textAlign: 'left',
      transition: 'height 0.15s, transform 0.15s',
      transitionDelay: '0.05s, 0.05s',
      willChange: 'transform',

      '& h6': {
        marginBottom: 4,
        fontSize: 16,
        lineHeight: 24 / 16,
      },

      '& p': {
        fontSize: 14,
        lineHeight: 20 / 14,
      },

      '& $expander': {
        position: 'absolute',
        left: 0,
        bottom: 80,
        width: '100%',
        height: 0,
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.1s, visibility 0.1s',
      },

      '& $button': {
        marginTop: 0,
      },
    },
  },
  cardIcon: {
    display: 'inline-flex',
    marginBottom: 16,

    '& svg': {
      width: 88,
      height: 88,
    },

    '&$withShadow': {
      position: 'absolute',
      top: 16,
      left: 16,
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0px 10px 20px rgba(16, 113, 170, 0.25)',
      borderRadius: 8,

      '& i': {
        color: theme.palette.background.default,
        fontSize: 24,
      },
    },
  },
  button: {
    marginTop: 32,
  },
  link: {
    marginTop: 8,
  },
  displayOnMobile: {
    textDecoration: 'none',
  },
  hideOnMobile: {
    display: 'none',
  },
  gridAreas: {
    '&:nth-child(1)': {
      gridArea: 'a',
    },
    '&:nth-child(2)': {
      gridArea: 'b',
    },
    '&:nth-child(3)': {
      gridArea: 'c',
    },
  },
  blueText: {
    color: theme.palette.primary.dark,
    fontWeight: 700,

    '& i': {
      verticalAlign: 'middle',
      marginLeft: 8,
    },
  },
  withShadow: {
    /* nesting */
  },
  expander: {
    /* nesting */
  },
  [theme.breakpoints.up('sm')]: {
    cardsWrapper: {
      gridTemplate: '"a a b b" auto ". c c ." auto / 1fr 1fr 1fr 1fr',
      gap: 30,

      '&$separated': {
        '& $card': {
          padding: '40px 10px',

          '&:nth-child(2)::before': {
            top: '50%',
            left: 0,
            width: 2,
            height: 'calc(100% - 80px)',
          },
        },

        '& br': {
          display: 'none',
        },
      },
    },
    card: {
      padding: '24px 16px',
      borderRadius: 40,

      '&$expandable': {
        padding: '24px 16px',
        textAlign: 'center',

        '& h6': {
          marginBottom: 8,
          fontSize: 24,
          lineHeight: 32 / 24,
        },

        '& p': {
          fontSize: 16,
          lineHeight: 24 / 16,
        },
      },
    },
    cardIcon: {
      '&$withShadow': {
        position: 'static',
        width: 88,
        height: 88,
        borderRadius: 24,

        '& i': {
          fontSize: 48,
        },
      },
    },
    link: {
      marginTop: 16,
    },
    button: {
      marginTop: 24,
    },
  },
  [theme.breakpoints.up('md')]: {
    cardsWrapper: {
      gridTemplate: '"a b c" / 1fr 1fr 1fr',

      '&$separated': {
        '& $card': {
          '&:first-child': {
            paddingLeft: 0,
          },
          '&:last-child': {
            paddingRight: 0,
          },

          '&:nth-child(3)::before': {
            top: '50%',
            left: 0,
            width: 2,
            height: 'calc(100% - 80px)',
          },
        },

        '& $cardIcon': {
          marginBottom: 16,
        },

        '& br': {
          display: 'inline',
        },
      },
    },
    card: {
      '&$expandable': {
        '& $link': {
          display: 'none',
        },

        '&:hover': {
          height: 'calc(100% + 80px)',
          transform: 'translateY(-40px)',

          '& $expander': {
            opacity: 1,
            visibility: 'visible',
            transitionDelay: '0.1s, 0.1s',
          },
        },
      },
    },
    cardIcon: {
      marginBottom: 8,
      '&$withShadow': {
        marginBottom: 24,
      },
    },
    blueText: { display: 'none' },
    displayOnMobile: {
      display: 'none',
    },
    hideOnMobile: { display: 'block', height: '100%' },
  },
  [theme.breakpoints.up('lg')]: {
    cardsWrapper: {
      '&$separated': {
        gap: 30,

        '& $card': {
          '&:nth-child(n+2)::before': {
            left: -15,
          },

          '&:nth-child(n)': {
            padding: 40,
          },
        },

        '& br': {
          display: 'none',
        },
      },
    },
    card: {
      padding: 40,

      '&$expandable': {
        padding: '40px 16px',

        '& $expander': {
          bottom: 96,
        },
      },
    },
    cardIcon: {
      marginBottom: 16,

      '&$withShadow': {
        marginBottom: 32,
      },
    },
    button: {
      marginTop: 32,
    },
  },
}));

export default useStyles;
