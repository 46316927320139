import { Typography } from '@material-ui/core';
import { format } from 'date-fns';

import useStyles from '@components/common/slide-with-testimonial/styles';
import { Rating } from '@store/landings';

import StarsGenerator from '../stars-generator';

export interface Review {
  createdAt: string;
  rating: Rating;
  title?: string;
  description: string;
  owner: string;
}

const SlideWithTestimonial: React.FC<Review> = (props) => {
  const { createdAt, rating, title, description, owner } = props;
  const classes = useStyles();

  return (
    <div className={classes.testimonial} key={title}>
      <div className={classes.ratingWrapper}>
        <StarsGenerator rating={rating} />
      </div>
      <Typography variant="body1" className={classes.title}>
        {title && <>&quot;{title}&quot;</>}
      </Typography>
      <Typography variant="body2" className={classes.description}>
        {description}
      </Typography>
      <Typography variant="body2" className={classes.owner}>
        {owner}
        {createdAt && ` - ${format(new Date(createdAt), 'd LLLL yyyy')}`}
      </Typography>
    </div>
  );
};

export default SlideWithTestimonial;
