import { CircularProgress, Container } from '@material-ui/core';
import { Fragment, useRef } from 'react';

import useStyles from '@components/common/testimonials/styles';

import ReactSlick from '../react-slick';
import SlideWithTestimonial, { Review } from '../slide-with-testimonial';

interface Props {
  reviews: Review[];
  isLoading: boolean;
}

const Testimonials: React.FC<Props> = (props) => {
  const { reviews, isLoading } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const slickBreakpoints = [
    {
      breakpoint: 659,
      settings: {
        slidesToShow: 1,
        arrows: true,
      },
    },
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        arrows: false,
      },
    },
  ];

  const classes = useStyles();

  const testimonialsCards = reviews?.map((review) => (
    <Fragment key={review.title + review.owner}>
      <SlideWithTestimonial
        createdAt={review.createdAt}
        rating={review.rating}
        title={review.title}
        description={review.description}
        owner={review.owner}
      />
    </Fragment>
  ));

  return (
    <section className={classes.testimonialWrapper} ref={wrapperRef}>
      {!isLoading && !!reviews.length && (
        <Container maxWidth={false}>
          <ReactSlick
            cssEase="ease"
            slides={testimonialsCards}
            swipeToSlide
            infinite
            dots
            breakpoint={slickBreakpoints}
            slidesToScroll={1}
            slidesToShow={3}
            swipe
            arrows
            autoplay
            autoplaySpeed={3000}
            speed={500}
            pauseOnHover
          />
        </Container>
      )}
      {isLoading && (
        <Container className={classes.loaderWrapper}>
          <CircularProgress />
        </Container>
      )}
    </section>
  );
};

export default Testimonials;
