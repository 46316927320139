import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  one: {
    display: 'block',
    position: 'relative',
    background: 'url(/png/stars-sprite.png) repeat-x',
    backgroundSize: '28px 54px',
    float: 'left',
    height: 28,
    lineHeight: 28,
    marginRight: 6,
    overflow: 'hidden',
    textAlign: 'left',
    width: 140,
  },
  two: {
    display: 'block',
    position: 'absolute',
    background: 'url(/png/stars-sprite.png) 0 -27px repeat-x',
    backgroundSize: '28px 54px',
    height: 28,
    left: 0,
    lineHeight: 28,
    top: 0,
    width: 0,
  },
}));

export default useStyles;
